export const UrlCollection = {
  Home: "/",
  Slider: "/bai-viet-trang-chu",
  PlanningAnnouncementProcess: "/quy-trinh-cong-bo-quy-hoach",
  EmailTemplate: "/email-template",
  RoleManagement: "/role-management",
  ContactManagement: "/contact-management",
  EmailGenerated: "/email-generated",
  UserManagement: "/quan-ly-nguoi-dung",
  CommuneManagement: "/quan-ly-xa-phuong",
  DistrictManagement: "/quan-ly-quan-huyen",
  ProvinceManagement: "/quan-ly-thanh-pho",
  LinkGroup: "/danh-sach-nhom",
  ServiceLink: "/danh-sach-lien-ket",
  UserLogHistory: "/nhat-ky-nguoi-dung",
  LandType: "/quan-ly-loai-dat",
  LandTypeDetail: "/quan-ly-chi-tiet-loai-dat",
    News: "/tin-tuc",
    Incentives: "/chinh-sach-uu-dai",
    AdminProcedures: "/thu-tuc-hanh-chinh",

    CategoryParent: "/category-parent",
    DocumentManagement: "/document-management",
  //--- Account
  Login: "/dang-nhap",
  Register: "/dang-ky",
  ForgotPassword: "/quen-mat-khau",
  ConfirmCode: "/xac-nhan",
  ResetPassword: "/dat-lai-mat-khau",
  MapData: '/ban-do',
  CreateMapData: '/thiet-dat-thong-so-ban-do/:mapKey',
  AccessDenied: '/403',
  OpinionForm: '/tao-form-y-kien',

  //--- Table structure
  TableStructure: '/sua-cau-truc-bang'
};
